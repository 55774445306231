@import "../../../assets/scss/variables";

.rmContactForm {
    h2 {
        font-size: 36px;
        line-height: 45px;

        margin-bottom: 54px;

        color: #010F54;
    }
    .singleContactForm {
        margin-top: 35px;
        input {
            font-weight: 500;

            width: 100%;
            padding-bottom: 27px;

            color: #010F54;
            border-width: 0 0 1px;
            border-style: none none solid;
            border-color: currentcolor currentcolor #A9C2CB;
            border-image: none;
            outline: none;

            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            -moz-border-right-colors: none;
            -moz-border-top-colors: none;
            &:focus {
                border-bottom: 1px solid $hover-color;
            }
        }
        textarea {
            width: 100%;
            min-height: 80px;
            margin-bottom: 50px;

            resize: none;

            color: #010F54;
            border-width: 0 0 1px;
            border-style: none none solid;
            border-color: currentcolor currentcolor #A9C2CB;
            border-image: none;
            outline: none;

            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            -moz-border-right-colors: none;
            -moz-border-top-colors: none;
            &:focus {
                border-bottom: 1px solid $hover-color;
            }
        }
    }
}

.rmContactForm .singleContactForm input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #010F54;
}

.rmContactForm .singleContactForm input::-moz-placeholder {
    /* Firefox 19+ */
    color: #010F54;
}

.rmContactForm .singleContactForm input:-ms-input-placeholder {
    /* IE 10+ */
    color: #010F54;
}

.rmContactForm .singleContactForm input:-moz-placeholder {
    /* Firefox 18- */
    color: #010F54;
}

.rmContactForm .singleContactForm textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #010F54;
}

.rmContactForm .singleContactForm textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #010F54;
}

.rmContactForm .singleContactForm textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #010F54;
}

.rmContactForm .singleContactForm textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #010F54;
}

@media #{$lg-layout} {
    .goggle__map {
        width: 512px;
    }
    .rmContactForm h2 {
        font-size: 30px;
        line-height: 43px;

        margin-bottom: 37px;
    }
    .contactThumb img {
        width: 100%;
    }
}

@media #{$md-layout} {
    .rmContactForm h2 {
        font-size: 23px;
        line-height: 36px;

        margin-bottom: 25px;
    }
    .contactThumb img {
        width: 100%;
    }
}

@media #{$sm-layout} {
    .rmContactForm h2 {
        font-size: 25px;
        line-height: 38px;

        margin-bottom: 18px;
    }
    .contactThumb {
        margin-top: 30px;
    }
    .contactThumb img {
        width: 100%;
    }
}

@media #{$xs-layout} {
    .contactThumb img {
        width: 100%;
    }
    .rmContactForm h2 {
        font-size: 20px;
        line-height: 31px;

        margin-bottom: 0;
    }
}
