@import "../../../assets/scss/variables";


.registerArea {
    min-height: 100vh;
    background-color: $theme-grey-light;
    width: 100%;
    

    .registerContainer {
        padding: 150px 0;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .registerBox {
            border-radius: 1em;
        }

        input {
            border-radius: 7px;
            border: none;
            line-height: 40px;
            font-size: 18px;
            padding: 5px 60px 5px 40px;
            min-width: 200px;
            width: 300px;
            max-width: 900px;
            position: relative;
            border: 2px solid $theme-grey-medium;
            &::placeholder {
                color: $theme-grey-medium;
                font-weight: 200;
            }
            &:focus, &:hover {
                border: 2px solid $theme-blue;
            }
            &::before {
                content:"@";
                position: absolute;
                top: 10px;
                left: 10px;
                color: $theme-blue;
            }
        }
    }

}
 
.register {
    color: blue;
}   