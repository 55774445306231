@import "../../../assets/scss/variables";

.headerArea {
    padding: 100px 0 70px 0;
    min-height: 350px;
    width: auto;
    transition: 0.3s;
    background-color: $theme-grey-light;
    .headerAreaContainer {
        margin-bottom: -170px;
    }
}

.header{
    margin-top: 200px
}

.title {
    color: $theme-color;
    font-size: 2.5em;
    padding: 50px;
    max-width: 800px;
    margin: auto;
}

.quickTask {
    border-radius: 1.5em;
    text-align: center;
    padding: 3em;
    box-shadow: 0 5px 7px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
    &:not(.quickTask_3):hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 0 5px 7px rgba(0,0,0,0.1);
    }
    h2 {
        color: white;
        font-size: 1.6rem;
        margin-bottom: 0;
        span {
            font-size: 125%;
            display: block;
        }
    }
    &_1 {
        background-color: $theme-color;
    }
    &_2 {
        background-color: $theme-blue;
    }
    &_3 {
        background-color: $theme-grey-medium;
    }
    .plus {
        font-size: 5em;
        color: white;
        font-family: sans-serif;
        font-weight: 700;
    }
}

