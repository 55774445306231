@import "../../assets/scss/variables";


.AccountMenu {
    li {
        font-size: 1.4em;
        margin-bottom: 1em;
        a {
            color: $theme-color;
            &:hover {
                color: $theme-blue;
            }        
            &.Current {
                color: $theme-blue;
                font-weight: 500;
            }
        }

    }
}


