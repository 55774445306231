/*--------------------
Reset Styles
----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;700&display=swap');
 
* {
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;

    height: 0;
}

[hidden] {
    display: none;
}

html {
    font-size: 100%;
    scroll-behavior: smooth;
    overflow-y: scroll;

    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
    font-family: $body-font;
}

body {
    line-height: 1.5;

    margin: 0;

    color: #141412;
}

a {
    text-decoration: none;

    color: #CA3C08;
}

a:visited {
    color: #AC0404;
}

a:focus {
    outline: thin dotted;
}

a:active,
a:hover {
    color: #fff;
    outline: 0;
}

a:hover {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $body-font;
    line-height: 1.3;

    clear: both;
}

h1 {
    font-size: 48px;

    margin: 33px 0;
}

h2 {
    font-size: 30px;

    margin: 25px 0;
}

h3 {
    font-size: 22px;

    margin: 22px 0;
}

h4 {
    font-size: 20px;

    margin: 25px 0;
}

h5 {
    font-size: 18px;

    margin: 30px 0;
}

h6 {
    font-size: 16px;

    margin: 36px 0;
}

address {
    font-style: italic;

    margin: 0 0 24px;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

mark {
    color: #000;
    background: #FF0;
}

p {
    margin: 0 0 24px;
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 14px;

    -webkit-hyphens: none;
            hyphens: none;

        -ms-hyphens: none;
}

pre {
    font-family: monospace;
    font-size: 14px;

    overflow: auto;

    margin: 20px 0;
    padding: 20px;

    white-space: pre-wrap;
    word-wrap: break-word;

    color: #666;
    background: #F5F5F5;
}

blockquote,
q {
    quotes: none;
    -webkit-hyphens: none;
            hyphens: none;

        -ms-hyphens: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
}

blockquote {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;

    margin: 24px 40px;
}

blockquote blockquote {
    margin-right: 0;
}

blockquote cite,
blockquote small {
    font-size: 14px;
    font-weight: normal;

    text-transform: uppercase;
}

blockquote em,
blockquote i {
    font-weight: 300;
    font-style: normal;
}

blockquote strong,
blockquote b {
    font-weight: 400;
}

small {
    font-size: smaller;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;

    position: relative;

    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

dl {
    margin: 0 20px;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0 0 20px;
}

// iframe {
//     pointer-events: none;
// }

menu,
ol,
ul {
    margin: 16px 0;
    padding: 0 0 0 40px;
}

ul {
    list-style-type: square;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

li > ul,
li > ol {
    margin: 0;
}

li {
    font-size: 17px;
}

img {
    vertical-align: middle;

    -ms-interpolation-mode: bicubic;
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    min-width: inherit;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;

    border: 1px solid #C0C0C0;
}

legend {
    padding: 0;

    white-space: normal;

    border: 0;
}

button,
input,
select,
textarea {
    font-size: 100%;

    max-width: 100%;
    margin: 0;

    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;

    -webkit-appearance: button;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}

input[type="search"] {
    /* Don't cut off the webkit search cancel button */
    width: 270px;
    padding-right: 2px;

    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;

    border: 0;
}

textarea {
    overflow: auto;

    vertical-align: top;
}

table {
    font-size: 14px;
    line-height: 2;

    width: 100%;
    margin: 0 0 20px;

    border-spacing: 0;
    border-collapse: collapse;

    border-bottom: 1px solid #EDEDED;
}

caption,
th,
td {
    font-weight: normal;

    text-align: left;
}

caption {
    font-size: 16px;

    margin: 20px 0;
}

th {
    font-weight: bold;

    text-transform: uppercase;
}

td {
    padding: 6px 10px 6px 0;

    border-top: 1px solid #EDEDED;
}

del {
    color: #333;
}

ins {
    text-decoration: none;

    background: #FFF9C0;
}

hr {
    height: 1px;
    margin: 0 0 24px;

    border: 0;
    background-size: 4px 4px;
}

/* Placeholder text color -- selectors need to be separate to work. */

::-webkit-input-placeholder {
    color: #7D7B6D;
}

:-moz-placeholder {
    color: #7D7B6D;
}

::-moz-placeholder {
    color: #7D7B6D;
}

:-ms-input-placeholder {
    color: #7D7B6D;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
.bgImg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.card-body {
    padding: 0;
}

.card {
    border: none;
}

.card-header {
    border-bottom: 0;
}

.subscribe-form {
    .rm__btn {
        font-size: 18px;
        font-weight: 500;
        line-height: 38px;

        position: relative;
        z-index: 1;

        display: inline-block;

        width: 100%;
        height: 40px;
        padding: 0 40px;

        transition: all 0.5s ease 0s;
        text-align: center;

        color: #FFFFFF;
        border: none;
        border-radius: 24px;
        background: rgba(0, 0, 0, 0) linear-gradient(-90deg, $theme-grey-medium, $theme-blue)
        repeat scroll 0 0;

        &:before {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            content: "";
            transition: all 0.5s ease 0s;

            opacity: 0;
            border-radius: 24px;
            background: rgba(0, 0, 0, 0) linear-gradient(90deg, $theme-grey-medium, $theme-blue)
            repeat scroll 0 0;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.rmSidebar {
    .nav-item {
        .nav-link {
            &.active {
                color: #1344A5 !important;
            }
        }
    }
}

.hero-slider {
    .swiper-pagination {
        bottom: 30px;
        &-bullet {
            width: 10px;
            height: 10px;
            &-active {
                background-color: #FFF;
            }
        }
    }
}

.swiper-container {
    padding-bottom: 5px;
    overflow-x: visible;
    overflow-y: visible
}


.swal2-popup {
    border-radius: 15px;
}

.swal2-actions button{
    border-radius: 7px;
    background: initial;
    font-size: 1em;
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 107, 255, 0.4)!important;
    }
    &.swal2-confirm {
        background-color: $theme-blue;
        color: #fff;
        border: 2px solid $theme-blue;
    }
    &.swal2-deny {
        background-color: white;
        color: $theme-blue;
        border: 2px solid $theme-blue;
    }
    &.swal2-cancel {
        background-color: $danger;
        color: #fff;
        border: 2px solid $danger;
    }

}






.atcb {
    display: none;
  }
  
  .atcb_button_wrapper {
    display: inline-block;
    padding: 5px;
    position: relative;
  }
  
  .atcb_button {
    align-items: center;
    background: rgb(245, 245, 245);
    border: 1px solid rgb(210, 210, 210);
    border-radius: 6px;
    -webkit-box-shadow: 1px 2px 10px 0px rgba(0,0,0,.4); 
    box-shadow: 1px 2px 10px 0px rgba(0,0,0,.4);
    color: rgb(51, 51, 51);
    cursor: pointer;
    display: flex;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    max-width: 300px;
    min-width: 150px;
    padding: 10px 16px 11px 16px;
    position: relative;
    text-align: center;
    touch-action: manipulation;  
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    width: auto;
  }
  .atcb_button:focus,
  .atcb_button:hover {
    background: rgb(255, 255, 255);
  }
  @media only screen and (max-width: 575px) {
    .atcb_button {
      font-size: 14px;
    }
  }
  
  .atcb_button.atcb_active {
    background: rgb(255, 255, 255);
    border-radius: 6px 6px 3px 3px;
    -webkit-box-shadow: 1px 5px 15px 0px rgba(0,0,0,.5);
    box-shadow: 1px 5px 15px 0px rgba(0,0,0,.5);
    margin: -2px -4px;
    padding: 12px 20px 13px 20px;
    z-index: 90;
  }
  
  .atcb_icon {
    height: 16px;
    display: inline-block;
    margin-bottom: 4px;
    margin-right: 10px;
  }
  .atcb_icon svg {
    height: 100%;
    color: rgb(51, 51, 51);
    width: auto;
  }
  
  .atcb_list {
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    display: block;
    font-family:Arial, Helvetica, sans-serif;
    max-width: 100%;
    position: absolute;
    padding: 0 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    min-width: 10em;
    z-index: 80;
  }
  
  .atcb_list.atcb_modal {
    position: fixed;
    width: 16em;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  
  .atcb_list_item {
    align-items: center;
    background: rgb(250, 250, 250);
    border: 1px solid rgb(210, 210, 210);
    border-top: 0;
    -webkit-box-shadow: 1px 2px 8px 0px rgba(0,0,0,.3); 
    box-shadow: 1px 2px 8px 0px rgba(0,0,0,.3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    left: 50%;
    position: relative;
    padding: 12px 18px;
    text-align: left;
    transform: translate(-50%);
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }
  .atcb_list_item:focus,
  .atcb_list_item:hover {
    background: rgb(255, 255, 255);
    -webkit-box-shadow: 1px 2px 10px 0px rgba(0,0,0,.4); 
    box-shadow: 1px 2px 10px 0px rgba(0,0,0,.4);
    color: rgb(0, 0, 0);
  }
  @media only screen and (max-width: 575px) {
    .atcb_list_item {
      font-size: 14px;
    }
  }
  
  .atcb_list.atcb_generated_button .atcb_list_item:first-child {
    padding-top: 20px;
  }
  
  .atcb_list:not(.atcb_generated_button) .atcb_list_item:first-child {
    border-radius: 6px 6px 0 0;
  }
  
  .atcb_list_item:last-child {
    border-radius: 0 0 6px 6px;
  }
  
  
  .atcb_list_item .atcb_icon {
    margin-right: 8px;
    width: 18px;
  }
  
  .atcb_bgoverlay {
    background: rgba(20,20,20,.2);
    backdrop-filter: blur(2px);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 70;
  }
  .atcb_bgoverlay.atcb_click:hover {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill-rule='evenodd' d='M11.991.69a2.35 2.35 0 0 1 3.318-.009c.918.911.922 2.392.009 3.307l-4.009 4.014 4.013 4.018c.906.909.893 2.38-.027 3.287a2.35 2.35 0 0 1-3.307-.004l-3.985-3.99-3.993 3.997a2.35 2.35 0 0 1-3.318.009c-.918-.911-.922-2.392-.009-3.307l4.009-4.014L.678 3.98C-.228 3.072-.215 1.6.706.693a2.35 2.35 0 0 1 3.307.004l3.985 3.99z'/%3E%3C/svg%3E") 32 32, pointer;
  }
  