@import "../../../assets/scss/variables";

.DashboardContent {
    margin-top: 200px;
}
.meetingDetails {
    border-radius: 1.5em;
    padding: 3em;
    box-shadow: 0 5px 7px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
    background-color: white;
    align-self: flex-start;
}

.MeetingDetailsContainer {
    min-height: 100vh;
}

.meetingList {
    border-radius: 1.5em;
    padding: 1.5em;
    box-shadow: 0 5px 7px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
    background-color: white;
    &Container {
        min-height: 400px;
        max-height: 90vh;
        overflow-y: auto;
        height: 100%;
        width: 100%;
        &::-webkit-scrollbar {
            width: 5px;
            height: 80%;
            margin-right: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background: rgb(204, 204, 204);
            border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(172, 172, 172);
        }
    }
    &Item {
        width: 100%;
        padding: 0.5em 1.5em;
        transition: all 0.3s ease-in-out;
        border-radius: 1em;
        margin-top: 1em;
        margin-bottom: 0.5em;
        position: relative;
        &:after {
            content: " ";
            position: absolute;
            width: 80%;
            height: 1px;
            background-color: $theme-grey-light;
            left: 10%;
            bottom: -0.5em;
        }
        &:last-child:after {
            display: none;
        }
        &:hover {
            background-color: #f6f6f6;
            cursor: pointer;
        }
    }
    h4 {
        font-weight: 400;
        color: $theme-color;
        margin-bottom: 0;
    }
}

.hiddenDetails {
    position: absolute;
    top: 0;
    right: -100vw;
    width: calc(100% - 30px);
    transition: all 0.4s ease-in-out;
    opacity: 0
}

.activeDetails {
    right: 15px!important;
    opacity: 1;
}

