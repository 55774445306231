
/*===== Colors =====//*/
$theme-color: #040037;
$body-color: #040037;
$heading-color: #0B3558;
$hover-color: #0F00DC;

$theme-blue: #006BFF;
// $theme-grey-medium: #CCCCCC;
$theme-grey-light: #EDEDED;
$theme-grey-medium: #d1d1d1;

$danger: #DC5858;


$theme-colors: (
  "theme-blue": $theme-blue,
  "theme-color": $theme-color,
  "theme-grey-light": $theme-grey-light,
  "theme-grey-medium": $theme-grey-medium,
  "danger": $danger,
);

/*===== Font Family =====//*/
$body-font: 'Readex Pro', sans-serif;
$heading-font: 'Readex Pro', sans-serif;

/*===== Line Height =====//*/
$body-line-height: 24px;


/*===== Radius  =====//*/
$theme-radius: 3px;


/*==== Font size =====//*/
$body-font-size: 14px;


/*===== Border color ======//*/
$border-color-light: #eeeeee;
$border-color-medium: #aaaaaa;
$border-color-dark: #333333;


/*===== Alerts color  =====//*/

$color-primary: #337ab7;
$color-success: #5cb85c;
$color-info: #5bc0de;
$color-warning: #f0ad4e;
$color-danger: #d9534f;
$pchart-color: #464647;
$pchart-color-hover: #FFFFFF;
$pchart-border-color: $pchart-color;
$pchart-button-size: 40px;
$pchart-border-radius: 5px;
$pchart-padding: 20px;
$pchart-margin: 20px;
$pchart-bar-toggled-color: #CA0097;
$pchart-bar-toggled-color-inverted: #FFFFFF;



/*===== Social Colors =====//*/

$facebook:                 #3B5998;
$twitter:                  #00ACEE;
$google-plus:              #DD4B39;
$pinterest:                #C8232C;
$linkedin:                 #0E76A8;
$flickr:                   #FF0084;
$dribbble:                 #EA4C89;
$google:                   #DD4B39;
$skype:                    #00AFF0;
$youtube:                  #C4302B;
$vimeo:                    #86C9EF;
$yahoo:                    #720E9E;
$paypal:                   #00588B;
$delicious:                #205CC0;
$flattr:                   #F67C1A;
$android:                  #A4C639;
$tumblr:                   #34526F;
$wikipedia:                #333333;
$stumbleupon:              #F74425;
$foursquare:               #25A0CA;
$digg:                     #191919;
$spotify:                  #81B71A;
$reddit:                   #C6C6C6;
$cc:                       #688527;
$vk:                       #2B587A;
$rss:                      #EE802F;
$podcast:                  #E4B21B;
$dropbox:                  #3D9AE8;
$github:                   #171515;
$soundcloud:               #FF7700;
$wordpress:                #1E8CBE;
$yelp:                     #C41200;
$scribd:                   #666666;
$cc-stripe:                #008CDD;
$drupal:                   #27537A;
$get-pocket:               #EE4056;
$bitbucket:                #0E4984;
$stack-overflow:            #EF8236;
$hacker-news:               #FF6600;
$xing:                     #126567;
$instagram:                #3F729B;
$quora:                    #A82400;
$openid:                   #E16309;
$steam:                    #111111;
$amazon:                   #E47911;


/* Layouts*/

$sp-layout : 'only screen and (min-width: 1920px)';
$xx-layout : 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$xlg-layout : 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$xs-layout: 'only screen and (max-width: 575px)';

/* container 450px*/


$xxs-layout: 'only screen and (max-width: 479px)';
/* container 300px*/



