@import "../../../assets/scss/variables";

.headerArea {
    padding: 150px 0 60px 0;
    min-height: 350px;
    width: auto;
    transition: 0.3s;
    background-color: $theme-grey-light;
    .headerAreaContainer {
        height: 100%;
        h1 {
            color: $theme-color;
            font-size: 3em;
        }
        p {
            font-size: 2em;
        }
    }
}

.header{
    margin-top: 200px
}



