@import "../../../assets/scss/variables";

.headerArea {
    padding: 150px 0 60px 0;
    min-height: 350px;
    width: auto;
    transition: 0.3s;
    .headerAreaContainer {
        height: 100%;
        h1 {
            color: $theme-color;
            font-size: 3em;
        }
        p {
            font-size: 2em;
        }
    }
}

.chartItem {
    border-radius: 1.5em;
    padding: 3em;
    box-shadow: 0 5px 7px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    margin: 20px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



