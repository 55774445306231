@import "../../../assets/scss/variables";

.heroSlider {
    height: calc( 100% + 80px );
    overflow: hidden;
    margin-bottom: -80px;
    .htSwiperButtonNav {
        font-size: 40px;

        width: 50px;
        height: 50px;
        padding: 0;

        color: #FFF;
        border: none;
        background: none;

        &:after {
            font-size: 0;
        }
    }

    .swiperButtonPrev {
        left: 50px;
    }

    .swiperButtonNext {
        right: 50px;
    }
}

.sliderOne {
    // @media screen and (min-width: 768px) {
    //     margin-bottom: 10em;
    // }
    .sliderFixedHeight {
        height: 900px;
        max-height: 100vh;
    }
    .slide {
        padding-top: 100px;
    }
    .swiper-container {
        // overwrite le preset de swiper < overflow: hidden >
        overflow: unset!important;
    }
}


.bgGrey {
    background-color: $theme-grey-light;
}

.slideInput {
    border-radius: 7px;
    border: none;
    line-height: 40px;
    font-size: 18px;
    padding: 5px 60px 5px 40px;
    border: 2px solid white;

    &::placeholder {
        color: $theme-grey-medium;
        font-weight: 200;
    }
    &:focus {
        border: 2px solid $theme-blue;
    }
}

.slide {
    .content {
        h1 {
            line-height: 65px;
            margin-bottom: 25px;
            margin-top: 2.5em;
            color: #FFFFFF;
            @media screen and (min-width: 768px){
            margin-top: inherit;
            width: 130%;
            }
        }
        p {
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-bottom: 40px;
            color: rgba(255,255,255, 0.8);
        }
    }
    .slideFrontImg {
        text-align: right;
        position: relative;
        @media screen and (min-width: 768px){
            height: 50vh;
        }
        img {
            border-radius: 3rem;
            box-shadow: 0px 0px 99px rgba(15,0,220,0.18);
            @media screen and (min-width: 768px){
                position: absolute;
                top: 20vh;
                left: 10%;
                height: 100%;
                margin-top: 5em;
            }
        }
    }
    .socialMedia {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -54px;
        a:hover svg {
            margin-top: -5px;
            height: 40px;
        }
        svg {
            transition: all 0.2s ease-in-out;
        }
    }
}

// Responsive Css

// @media #{$xlg-layout} {
//     .sliderOne .sliderFixedHeight {
//         height: 90vh;
//     }
// }

@media #{$lg-layout} {
    .slide .slideFrontImg img {
        height: 77%;
        border-radius: 2rem;

    }
    .sliderOne .sliderFixedHeight {
        height: 100vh;
    }
}

@media #{$md-layout} {
    .heroSlider {
        .htSwiperButtonNav {
            display: none;
        }
    }
    .slide .content h1 {
        line-height: 49px;
    }
    .slide .slideFrontImg img {
        height: 70%;
        border-radius: 2rem;
    }

    .sliderOne .sliderFixedHeight {
        height: 100vh;
    }
}

@media #{$sm-layout} {
    .heroSlider {
        .htSwiperButtonNav {
            display: none;
        }
    }
    .slide .content h1 {
        line-height: 45px;

        margin-bottom: 17px;
    }
    .slide .slideFrontImg img {
        width: 100%;
        height: auto;
        border-radius: 2rem;
    }
    .sliderOne .sliderFixedHeight {
        height: auto;
        min-height: calc( 100vh - 70px );
    }
    .sliderOne .sliderFixedHeight {
        padding: 120px 40px 70px;
    }
    .slide .slideFrontImg {
        margin-top: 26px;

        text-align: center;
    }
}

.wave {
    position: absolute;
    animation: sonar 20s infinite;
    border-radius: 100%;
    border: solid 2px $theme-blue;
    background-color: transparent;
    transition: 0.33s ease-in all;
    opacity: 0;
    box-shadow: inset 5px 5px 2px $theme-blue, inset -5px -5px 2px $theme-blue;
    transform: translate3d(0, 0, 0);
    width: 40px;
    height: 40px;
}

@keyframes sonar {
   0% {
        transform: scale(1);
        opacity: 0;
   }
   90% {
        transform: scale(100);
        opacity: 0.2;
   }
   100% {
        transform: scale(150);
        opacity: 0;
    }
}

.wave:nth-child(1) {
   animation-delay: 0000ms;
}

.wave:nth-child(2) {
   animation-delay: 5000ms;
}

.wave:nth-child(3) {
   animation-delay: 10000ms;
}

.wave:nth-child(4) {
   animation-delay: 15000ms;
}

