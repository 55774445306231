@import "../../assets/scss/variables";

.reasonsContainer {
    margin: 15% 0;
    .card {
        border-radius: 1.5em;
        padding: 3em;
        box-shadow: 0 5px 7px rgba(0,0,0,0.1);
        transition: all 0.3s ease-in-out;
        margin-bottom: 20px;
        background-color: white;
        a {
            font-size: 18px;
        }
    }
}

@media #{$xlg-layout} {
    .reasonsContainer {
        margin: 20% 0;
    }
}

@media #{$lg-layout} {
    .reasonsContainer {
        margin: 20% 0;
    }
}

@media #{$md-layout} {
    .reasonsContainer {
        margin: 30% 0;
    }
}

@media #{$sm-layout} {
    .reasonsContainer {
        margin: 40% 0;
    }
}
