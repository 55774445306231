@import "../../assets/scss/variables";

.feedbackPageWrapper {
    min-height: 100vh;

    @media #{$lg-layout,
    $md-layout,
    $xs-layout} {
        min-height: 500px;
    }

    @media #{$xs-layout} {
        min-height: 400px;
    }

    [class*="col-"] {
        height: auto;
        * {
            height: auto;
        }
    }
}

.feedbackPageContentWrap {
    font-size: 16px;

    height: auto;
    h2 {
        font-size: 120px;
        font-weight: 900;
        line-height: 1;

        color: #333;
        text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

        @media #{$xs-layout} {
            font-size: 100;
        }
    }

    h3 {
        font-size: 32px;

        margin-bottom: 12px;

        color: #333;

        @media #{$xs-layout} {
            font-size: 20px;

            margin-bottom: 5px;
        }
    }
    p {
        margin-bottom: 30px;
    }
}



.feedbackBox {
    border-radius: 1em;
    margin-top: 5em;
    input, textarea {
        border-radius: 7px;
        border: none;
        line-height: 25px;
        font-size: 18px;
        padding: 5px 60px 5px 40px;
        min-width: 200px;
        width: 100%;
        max-width: 900px;
        position: relative;
        border: 2px solid $theme-grey-medium;
        &::placeholder {
            color: $theme-grey-medium;
            font-weight: 200;
        }
        &:focus, &:hover {
            border: 2px solid $theme-blue;
        }
    }
}


.feedback {
    color: blue;
}  