@import "../../../assets/scss/variables";

.w18Btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 47px;

    position: relative;
    z-index: 1;

    display: inline-block;

    height: 50px;
    padding: 0 40px;

    transition: all 0.5s ease 0s;

    // color: #FFF;
    border: none;
    border-radius: 7px;
    outline: none;
    background: $theme-blue;
    &:hover {
        background: white;
        color: $theme-blue;
    }
    &:focus {
        outline: none;
    }
    &:visited {
        color: #FFF;
    }
    &Light {
        position: relative;
        z-index: 1;

        transition: all 0.5s ease 0s;

        color: #E05941;
        background: linear-gradient(-90deg, #EEF3F6, #EEF3F6);
        &:visited {
            color: #E05941;
        }
        &:hover {
            color: #FFF;
        }
    }
    &White {
        color: $theme-color;
        background: #fff none repeat scroll 0 0;
        &:hover {
            color: #fff;
            background: $theme-blue;
            &:visited {
                color: #FFF;
            }
        }
        &:visited {
            color: $theme-color;
        }
    }

    &Blue {
        color: white;
        background: $theme-blue none repeat scroll 0 0;
        border: 2px solid $theme-blue;

        &:hover {
            color: $theme-blue;
            background: #fff;
        }
    }

    &BorderBlue {
        border: 2px solid $theme-blue;
        color: $theme-blue!important;
        background: #fff;
        &:hover {
            color: white!important;
            background: $theme-blue none repeat scroll 0 0;
        }
    }

    &Abort {
        color: $danger;
        background: white;
        border: 2px solid white;
        &:hover {
            color: $danger;
            border: 2px solid $danger;
        }
        &:visited {
            color: $danger;
        }
    }
}
